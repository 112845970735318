// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bud-js": () => import("./../../../src/pages/bud.js" /* webpackChunkName: "component---src-pages-bud-js" */),
  "component---src-pages-citf-js": () => import("./../../../src/pages/citf.js" /* webpackChunkName: "component---src-pages-citf-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sme-js": () => import("./../../../src/pages/sme.js" /* webpackChunkName: "component---src-pages-sme-js" */),
  "component---src-pages-tvp-js": () => import("./../../../src/pages/tvp.js" /* webpackChunkName: "component---src-pages-tvp-js" */)
}

